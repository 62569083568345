<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <b-row class="mb-1">
      <b-col>
        <b-button class="mr-1" @click="generatePdf">Genera PDF Rimanenze</b-button>
        <b-button @click="generatePdfVuoto">Genera PDF Vuoto</b-button>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-end">
      <b-col class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn mr-2"
          @click="selectedOp('CARICO')"
        >
          Carica
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn mr-2"
          @click="selectedOp('SCARICO')"
        >
          Scarica
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn mr-2"
          @click="selectedOp('SPOSTA')"
        >
          Sposta
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn"
          @click="selectedOp('RETTIFICA')"
        >
          Rettifica
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <filters-badge-syncfusion
          :filters-used="filtersSearchBegin"
          @clear-filter="clearFilter"
        />
      </b-col>
    </b-row>
    <div class="col-lg-12 control-section mt-2 p-0">
      <div class="content-wrapper">
        <!-- <div>
          <ejs-dropdownlist
            id="dropdownlist"
            ref="dropdown"
            :data-source="ddData"
            width="220px"
            :fields="fields"
            :change="valueChange"
            :value="ddValue"
          />
          <span
            id="msg"
            ref="msgelement"
            v-html="loadTime"
          />
          <br>
        </div> -->
        <ejs-grid
          id="overviewgridquantityshop"
          ref="overviewgridquantityshop"
          locale="it-IT"
          :data-source="quantitaMagazzino"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :filter-settings="filterSettings"
          :text-wrap-settings="wrapSettings"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-filtering="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="false"
          height="600"
          :pdf-header-query-cell-info="pdfHeaderQueryCellInfo"
          :allow-pdf-export="true"
          :allow-excel-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :allow-grouping="false"
          :allow-paging="true"
          :page-settings="pageSettings"
          :context-menu-items="contextMenuItems"
          :context-menu-click="contextMenuClick"
          :data-bound="dataBound"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="60"
            />
            <e-column
              field="id"
              :visible="false"
              header-text="ID"
              :is-primary-key="true"
              width="130"
            />
            <e-column
              field="codiceMagazzino"
              header-text="Cod.Magazzino"
              width="150"
              :filter="filter"
              :template="templateCodMagazzino"
              :visible="false"
            />
            <e-column
              field="nomeMagazzino"
              header-text="Magazzino"
              width="130"
              clip-mode="EllipsisWithTooltip"
              :template="templateMagazzino"
            />
            <e-column
              field="codiceProdotto"
              header-text="cod. Prodotto"
              width="150"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
              :template="templateCodiceProdotto"
            /><!-- :template="templateNomeProdotto" -->
            <e-column
              field="nomeProdotto"
              header-text="Nome Prodotto"
              width="250"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            /><!-- :template="templateNomeProdotto" -->
            <e-column
              field="quantita"
              header-text="quantità"
              width="100"
              :filter="filter"
            /><!-- :template="templateQuantita" -->
            <e-column
              field="unitaMisura"
              header-text="U.M"
              width="80"
              :filter="filter"
            /><!-- :template="templateUnitaMisura" -->
            <e-column
              field="tipoProdotto"
              header-text="Tipo Prodotto"
              width="160"
              :filter="filter"
              :template="templateTipoProdotto"
            />
            <!-- <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="240"
              :template="cTemplate"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
            /> -->
          </e-columns>
        </ejs-grid>
      </div>
    </div>
    <WarehouseOperations
      ref="flow-warehouses-event-handler"
      @update-flow-warehouse="updateMovimenti"
    />
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll,
} from '@syncfusion/ej2-vue-grids'
import Ripple from 'vue-ripple-directive'
import { BCard, BAvatar, BBadge, BRow, BCol, BButton } from 'bootstrap-vue'
// import SidebarFlowWarehouseEventHandler from './eventHandlerSidebar/SidebarFlowWarehouseEventHandler.vue'
// import SettingsPdfExport from '@/components/pdfExportModal/SettingsPdfExport.vue'
import WarehouseOperations from '@/views/pages/portfolio/products/details/sidebarEventHandler/WarehouseOperations.vue'
import { quantitaAttualiMagazzini } from '@/@core/api-service/logistic/warehouses'
import { getPuntoVendita } from '@/@core/api-service/logistic/puntiVendita'
import FiltersBadgeSyncfusion from '@/@core/components/filter-table-syncfusion/FiltersBadgeSyncfusion.vue'
import moment from 'moment'
import { getProdotti } from '@/@core/api-service/catalog/products'

Vue.use(GridPlugin)

export default Vue.extend({
  components: {
    BCard,
    FiltersBadgeSyncfusion,
    WarehouseOperations,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filtersSearchBegin: [],
      filterSettings: { type: "Menu" },
      selectedOperation: '',
          cTemplate: function () {
        return { template : Vue.component('columnTemplate',{
            template: `<div class="image">
                  <img :src="image" :alt="altImage"/>
              </div>`,
              data: function() {
                  return {
                      data: {}
                  }
              },
              computed: {
                  image: function() {
                      return './' + this.data.EmployeeID + '.png'
                  },
                  altImage: function() {
                      return this.data.EmployeeID
                  }
              }
        })
      }
    },
    warehouse: {},
    wrapSettings: { wrapMode: 'Content' },
    commands: [
      { type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
      { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
      { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
      { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
      { type: 'Dettagli', id: 'Dettagli', target: '.e-column', buttonOption: { iconCss: 'e-icons e-settings', cssClass: 'e-flat' } }
    ],
    contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy','PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup'],
    pageSettings: { pageSize: 25, pageSizes: [25,50,100,200], pageCount: 5 },
    showModalSettingPdfExport: false,
    editSettings: { allowFiltering: true, allowEditing: false, allowAdding: false, allowDeleting: false, mode: 'Normal' },
    toolbar: ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print'],
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 10000,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    quantitaMagazzino: [],
    rows: [
      {
        id: 1,
        nome: 'Croccantini',
        unitaMisura: 'KG',
        quantita: 100,
        idFornitore: 'fornitrore2',

      },
    ],
    templateTipoMovimento() {
      return {
        template: Vue.component('templateTipoMovimento', {
        components: {
              BAvatar,
            },
            data() { return { data: {} } },
            template: `
              <div
                v-if="data.tipoMovimento === 'CARICO'"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex justify-content-center"><p class="my-auto">{{ data.tipoMovimento }}</p></div>
                  <b-avatar
                    :variant="'success'"
                    class="mr-1"
                  >
                    <feather-icon
                      icon="ArrowUpIcon"
                      size="16"
                    />
                  </b-avatar>
                </div>
              </div>
              <div v-else-if="data.tipoMovimento === 'SCARICO'">
                <div class="d-flex justify-content-between">
                  <div class="d-flex justify-content-center"><p class="my-auto">{{ data.tipoMovimento }}</p></div>
                  <b-avatar
                    :variant="'danger'"
                    class="mr-1"
                  >
                    <feather-icon
                      icon="ArrowDownIcon"
                      size="16"
                    />
                  </b-avatar>
                </div>
              </div>
              <div v-else-if="data.tipoMovimento === 'RETTIFICA'">
                <div class="d-flex justify-content-between">
                  <div class="d-flex justify-content-center"><p class="my-auto">{{ data.tipoMovimento }}</p></div>
                  <b-avatar
                    :variant="'primary'"
                    class="mr-1"
                  >
                    <feather-icon
                      icon="ArrowUpRightIcon"
                      size="16"
                    />
                  </b-avatar>
                </div>
              </div>
            `
          }),
        }
    },
    templateMagazzino() {
        return {
          template: Vue.component('templateMagazzino', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="primary"
            >
              {{ data.nomeMagazzino }}
            </b-badge>`,
          }),
        }
      },
    templateDataOperazione() {
        return {
          template: Vue.component('templateDataOperazione', {
            components: {
            },
            data() { return { data: {} } },
            computed: {
              dataOperatizioneComputed() {
                return moment(this.data.dataOperazione).format("DD/MM/YYYY HH:mm:ss")
              }
            },
            template: `
            <div>
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              {{ dataOperatizioneComputed }}
            </div>
            `,
          }),
        }
      },
    templateCodMagazzino() {
        return {
          template: Vue.component('templateCodMagazzino', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="primary"
            >
              {{ data.codiceMagazzino }}
            </b-badge>`,
          }),
        }
      },
    templateTipoProdotto() {
        return {
          template: Vue.component('templateTipoProdotto', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="info"
            >
              {{ data.tipoProdotto }}
            </b-badge>`,
          }),
        }
      },
    templateNomeProdotto() {
        return {
          template: Vue.component('templateNomeProdotto', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="info"
            >
              {{ data.nomeProdotto }}
            </b-badge>`,
          }),
        }
      },
    templateCodiceProdotto() {
        return {
          template: Vue.component('templateCodiceProdotto', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="info"
            >
              {{ data.codiceProdotto }}
            </b-badge>`,
          }),
        }
      },
    templateUnitaMisura() {
        return {
          template: Vue.component('templateUnitaMisura', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="danger"
            >
              {{ data.unitaMisura }}
            </b-badge>`,
          }),
        }
      },
    templateQuantita() {
        return {
          template: Vue.component('templateQuantita', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="danger"
            >
              {{ data.quantita }}
            </b-badge>`,
          }),
        }
      },
    templateCodiceProdotto() {
        return {
          template: Vue.component('templateCodiceProdotto', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="info"
            >
              {{ data.codiceProdotto }}
            </b-badge>`,
          }),
        }
      },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'Menu',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
    }
  },
  async mounted() {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    await quantitaAttualiMagazzini().then(res => {
      console.log(res)
      if (res.data.esito === 'OK') {
        // console.log('ciao')
        this.quantitaMagazzino = [...res.data.quantitaAttualeMagazzini].filter(el => el.tipoProdotto === 'UNIFICATO' && Number(el.idPuntoVendita) === Number(this.$route.params.shop))
        console.log('magazzino', this.quantitaMagazzino)
      }
    }).catch(e => e)
    // this.$refs.overviewgridquantityshop.filterByColumn('tipoProdotto', 'equal', 'UNIFICATO')
  },
  computed: {
  },
  methods: {
    selectedOp(operation) {
      this.$refs['flow-warehouses-event-handler'].setTitle(operation)
      this.$refs['flow-warehouses-event-handler'].openSidebar()

      // this.selectedOperation = operation
    },

    clearFilter(field) {
      this.$refs.overviewgridquantityshop.clearFiltering([field])
      const indexToRemove = this.filtersSearchBegin.findIndex(el => el.field === field)
      this.filtersSearchBegin[indexToRemove].active = false
      // this.fiters = this.filters
    },
    updateMovimenti(movimenti) {
      // console.log('movimenti', movimenti)
      movimenti.forEach(el => {
        const indexElement = this.quantitaMagazzino.findIndex(elMagazzino => Number(elMagazzino.idProdotto) === Number(el.idProdotto) && (Number(elMagazzino.codiceMagazzino) === Number(el.codiceMagazzino || String(elMagazzino.codiceMagazzino) === String(el.codiceMagazzino))))
        // console.log(indexElement)
        if (indexElement >= 0) {
          this.quantitaMagazzino[indexElement].quantita =
            el.tipoMovimento === 'CARICO' ? Number(this.quantitaMagazzino[indexElement].quantita) + Number(el.quantita) :
            el.tipoMovimento === 'SCARICO' ? Number(this.quantitaMagazzino[indexElement].quantita) - Number(el.quantita) :
            el.tipoMovimento === 'RETTIFICA' ? Number(el.quantita) : ''
            this.quantitaMagazzino[indexElement].quantita = this.quantitaMagazzino[indexElement].quantita.toFixed(2)
        } else {
          this.quantitaMagazzino.unshift(el)
        }
      })
      // console.log(this.quantitaMagazzino)
      this.$refs.overviewgridquantityshop.refresh()
      // console.log(this.quantitaMagazzino)
    },
    selectedOp(operation) {
      this.$refs['flow-warehouses-event-handler'].setTitle(operation)
      this.$refs['flow-warehouses-event-handler'].openSidebar()

      // this.selectedOperation = operation
    },
    dataBound: function() {
      // console.log(this.$refs.overviewgridquantityshop)
      // this.$refs.overviewgridquantityshop.autoFitColumns();
    },
    commandColumm(args) {
    },
    contextMenuClick(args) {
        // console.log('contextMenuClick', args)
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableBegin(args) {
      // console.log(action)
    },
    actionTableComplete(args) {
      if (args.action === 'filter') {
        const columnInfo = this.$refs.overviewgridquantityshop.getColumnByField(String(args.currentFilteringColumn))
        const columnFiltered = {
          field: columnInfo.field,
          headerText: columnInfo.headerText,
          value: args.currentFilterObject.value,
          active: true,
        }
        // const headerTextFilterUsed = allColumns.find(el => el.field === args.currentFilteringColumn)
        const esistenzaFilter = this.filtersSearchBegin.findIndex(el => el.field === args.currentFilteringColumn)
        if (esistenzaFilter >= 0) {
          this.filtersSearchBegin[esistenzaFilter].value = args.currentFilterObject.value
          this.filtersSearchBegin[esistenzaFilter].active = true
        } else {
          this.filtersSearchBegin.push(columnFiltered)
        }
      }
      if (args.action === 'clearFilter') {
        const indexSearch = this.filtersSearchBegin.findIndex(el => el.field === args.currentFilterColumn.field)
        if (indexSearch >= 0) {
          this.filtersSearchBegin[indexSearch].active = false
        }
      }
    },
    pdfHeaderQueryCellInfo() {
      // args.cell.row.pdfGrid.repeatHeader = true;
    },
    async toolbarClick(args) {
      // console.log(args)
      const id = JSON.stringify([{ id: this.$route.params.shop }])
      let nameShop = ''
      await getPuntoVendita(id).then(res => {
        console.log(res)
        if (res.data.esito === 'OK') {
          nameShop = res.data.puntovendita.nome
        }
      }).catch(e => e)
      if (args.item.id === 'Dettagli') {
        alert("Custom Toolbar Click...");
      }
      if (args.item.id === 'overviewgridquantityshop_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: `Rimanenze_${nameShop.trim()}_${moment().format('DD-MM-YYYY')}.pdf`,
            }
          // this.$bvModal.show('setting-pdf-export-modal')
          this.$refs.overviewgridquantityshop.pdfExport(exportProperties)
          // this.$refs.overviewgrid.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgridquantityshop_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: `Rimanenze_${nameShop.trim()}_${moment().format('DD-MM-YYYY')}.xlsx`,
            }
          this.$refs.overviewgridquantityshop.excelExport(exportProperties)
      }
      if (args.item.id === 'overviewgridquantityshop_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: `Rimanenze_${nameShop.trim()}_${moment().format('DD-MM-YYYY')}.csv`,
            }
          this.$refs.overviewgridquantityshop.csvExport(exportProperties)
      }
    },
    async generatePdf() {
      let prodotti = []
      await getProdotti().then(res => {
        if (res.data.esito === 'OK') {
          prodotti = [...res.data.prodotti].filter(el => el.tipoProdotto === 'Unificato')
        }
      })

      let headersMagazzini = [...new Set(this.quantitaMagazzino.map(el => el.nomeMagazzino))]
      headersMagazzini.unshift('id', 'Prodotto')
      headersMagazzini.push('Domicilio', 'Vivo ', 'Paghi Poco', 'Crai', 'Sisa')

      const prodottiMappati = prodotti.map(el => ({ ...headersMagazzini.reduce((acc,curr)=> (acc[curr]=' ', acc),{}), Prodotto: el.nome, id: el.id}))
      const prodottiPDF = prodottiMappati.map((el, i) => {
        const filtrati = this.quantitaMagazzino.filter(e => Number(e.idProdotto) === Number(el.id))
        filtrati.forEach(element => {
          if(Object.keys(el).includes(element.nomeMagazzino)) {
            el[element.nomeMagazzino] = element.quantita
          }
        })
        el.id = String(i)
        return el
      })
      window.jsPDF = window.jspdf.jsPDF
      let doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape", format: 'a3', compress: true })
      doc.setLineHeightFactor(0.75)
      doc.table(1, 1, prodottiPDF, headersMagazzini, { autoSize: true, fontSize : 9 })
      doc.save(`${'Rimanenze_'}` + `${moment().format('DD-MM-YYYY')}` + '.pdf')
    },
    async generatePdfVuoto() {
      let prodotti = []
      await getProdotti().then(res => {
        if (res.data.esito === 'OK') {
          prodotti = [...res.data.prodotti].filter(el => el.tipoProdotto === 'Unificato')
        }
      })

      let headersMagazzini = [...new Set(this.quantitaMagazzino.map(el => el.nomeMagazzino))]
      headersMagazzini.unshift('id', 'Prodotto')
      headersMagazzini.push('Domicilio', 'Prezzo Vivo ', 'Prezzo Paghi Poco', 'Prezzo Crai', 'Prezzi Sisa')

      const prodottiMappati = prodotti.map(el => ({ ...headersMagazzini.reduce((acc,curr)=> (acc[curr]=' ', acc),{}), Prodotto: el.nome, id: el.id}))
      const prodottiPDF = prodottiMappati.map((el, i) => {
        const filtrati = this.quantitaMagazzino.filter(e => Number(e.idProdotto) === Number(el.id))
        filtrati.forEach(element => {
          if(Object.keys(el).includes(element.nomeMagazzino)) {
            el[element.nomeMagazzino] = ' '
          }
        })
        el.id = String(i)
        return el
      })
      window.jsPDF = window.jspdf.jsPDF
      let doc1= new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape", format: 'a3', compress: true })
      doc1.setLineHeightFactor(0.75)
      doc1.table(1, 1, prodottiPDF, headersMagazzini, { autoSize: true, fontSize : 9 })
      doc1.save(`${'Lista Prodotti'}` + '.pdf')
    },
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Filter],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
